import Hero from '../comp/Hero.js';
import Servis from '../comp/Servis.js';
import WhyUs from '../comp/WhyUs.js';
import Pr from '../comp/Pr.js';


const Index = () => {
    return (
        <div>
      <Hero />
      <Servis />
      <WhyUs />
      <Pr />
        </div>
    )
}

export default Index;