const Servis = () => {
    return (
        <section className="">  
            <div className="flex flex-col items-center pt-36 container">

                <h2 className={` font-bold text-4xl  mb-3 max-w-[750px]  leading-normal text-gray-800`}>خدماتی که ما را <span className="text-primary">متمایز</span> می کند                </h2>
                <p className="text-xl font-medium text-gray-500">از مرحله ایده تا نصب و راه اندازی همراه شما هستیم</p>

                <div className="grid md:grid-cols-4 grid-cols-2  mt-14 md:gap-10 gap-5 ">


                    {/* item  */}
                    <div className="flex flex-col justify-center max-md:items-center items-start p-7  bg-[#F9F9FB] border border-white rounded-3xl shadow-2xl p-7  bg-[#F9F9FB] border border-white rounded-3xl shadow-2xl">
                        <div className="flex md:flex-row flex-col items-center justify-start  gap-4">
                            <div className="p-3 bg-primary text-white rounded-2xl">
                            <svg width="50px" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
 <path d="M20 12C20 16.4183 16.4183 20 12 20M20 12C20 7.58172 16.4183 4 12 4M20 12H4M12 20C7.58172 20 4 16.4183 4 12M12 20C14.001 17.8093 15.1388 14.9664 15.2006 12C15.1388 9.03363 14.001 6.19068 12 4M12 20C9.99898 17.8093 8.86235 14.9664 8.80055 12C8.86235 9.03363 9.99898 6.19068 12 4M4 12C4 7.58172 7.58172 4 12 4M6 20C6 21.1046 5.10457 22 4 22C2.89543 22 2 21.1046 2 20C2 18.8954 2.89543 18 4 18C5.10457 18 6 18.8954 6 20ZM22 20C22 21.1046 21.1046 22 20 22C18.8954 22 18 21.1046 18 20C18 18.8954 18.8954 18 20 18C21.1046 18 22 18.8954 22 20ZM6 4C6 5.10457 5.10457 6 4 6C2.89543 6 2 5.10457 2 4C2 2.89543 2.89543 2 4 2C5.10457 2 6 2.89543 6 4ZM22 4C22 5.10457 21.1046 6 20 6C18.8954 6 18 5.10457 18 4C18 2.89543 18.8954 2 20 2C21.1046 2 22 2.89543 22 4Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
 </svg>
                            </div>
                            <div className="flex flex-col  justify-center gap-3 md:items-start pt-[0.525rem]">

                                <div className="font-bold text-primary text-lg text-center">تحت وب</div>
                                <div>Web base</div>

                            </div>
                        </div>


                        <div className="text-gray-500 text-right pt-8 leading-loose	max-md:hidden">
                        با تحت وب بودن نرم افزار شما می توانید همه جا به اپ خود دسترسی داشته باشید.

                        </div>



                    </div>

                    {/* item  */}

                                        {/* item  */}
                                        <div className="flex flex-col justify-center max-md:items-center items-start p-7  bg-[#F9F9FB] border border-white rounded-3xl shadow-2xl p-7  bg-[#F9F9FB] border border-white rounded-3xl shadow-2xl">
                        <div className="flex md:flex-row flex-col items-center justify-start  gap-4">
                            <div className="p-3 bg-primary text-white rounded-2xl">
                            <svg width="50px" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
 <path d="M21 16V7.2C21 6.0799 21 5.51984 20.782 5.09202C20.5903 4.71569 20.2843 4.40973 19.908 4.21799C19.4802 4 18.9201 4 17.8 4H6.2C5.07989 4 4.51984 4 4.09202 4.21799C3.71569 4.40973 3.40973 4.71569 3.21799 5.09202C3 5.51984 3 6.0799 3 7.2V16M4.66667 20H19.3333C19.9533 20 20.2633 20 20.5176 19.9319C21.2078 19.7469 21.7469 19.2078 21.9319 18.5176C22 18.2633 22 17.9533 22 17.3333C22 17.0233 22 16.8683 21.9659 16.7412C21.8735 16.3961 21.6039 16.1265 21.2588 16.0341C21.1317 16 20.9767 16 20.6667 16H3.33333C3.02334 16 2.86835 16 2.74118 16.0341C2.39609 16.1265 2.12654 16.3961 2.03407 16.7412C2 16.8683 2 17.0233 2 17.3333C2 17.9533 2 18.2633 2.06815 18.5176C2.25308 19.2078 2.79218 19.7469 3.48236 19.9319C3.73669 20 4.04669 20 4.66667 20Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
 </svg>
                            </div>
                            <div className="flex flex-col  justify-center gap-3 md:items-start pt-[0.525rem]">

                                <div className="font-bold text-primary text-lg text-center">طراحی اپلیکیشن</div>
                                <div>Application</div>

                            </div>
                        </div>


                        <div className="text-gray-500 text-right pt-8 leading-loose	max-md:hidden">
                        ما با توسعه نرم افزار شما در تمامی پلفترم ها استفاده راحتی را در همه جا به شما می هدیه می دهیم.

                        </div>



                    </div>

                    {/* item  */}

                                        {/* item  */}
                                        <div className="flex flex-col justify-center max-md:items-center items-start p-7  bg-[#F9F9FB] border border-white rounded-3xl shadow-2xl p-7  bg-[#F9F9FB] border border-white rounded-3xl shadow-2xl">
                        <div className="flex md:flex-row flex-col items-center justify-start  gap-4">
                            <div className="p-3 bg-primary text-white rounded-2xl">
                            <svg width="50px" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
 <path d="M16 7C16 6.07003 16 5.60504 15.8978 5.22354C15.6204 4.18827 14.8117 3.37962 13.7765 3.10222C13.395 3 12.93 3 12 3C11.07 3 10.605 3 10.2235 3.10222C9.18827 3.37962 8.37962 4.18827 8.10222 5.22354C8 5.60504 8 6.07003 8 7M5.2 21H18.8C19.9201 21 20.4802 21 20.908 20.782C21.2843 20.5903 21.5903 20.2843 21.782 19.908C22 19.4802 22 18.9201 22 17.8V10.2C22 9.07989 22 8.51984 21.782 8.09202C21.5903 7.71569 21.2843 7.40973 20.908 7.21799C20.4802 7 19.9201 7 18.8 7H5.2C4.07989 7 3.51984 7 3.09202 7.21799C2.71569 7.40973 2.40973 7.71569 2.21799 8.09202C2 8.51984 2 9.07989 2 10.2V17.8C2 18.9201 2 19.4802 2.21799 19.908C2.40973 20.2843 2.71569 20.5903 3.09202 20.782C3.51984 21 4.0799 21 5.2 21Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
 </svg>
                            </div>
                            <div className="flex flex-col  justify-center gap-3 md:items-start pt-[0.525rem]">

                                <div className="font-bold text-primary text-lg text-center">نرم افزار های اداری</div>
                                <div>Office software</div>

                            </div>
                        </div>


                        <div className="text-gray-500 text-right pt-8 leading-loose	max-md:hidden">
                       ما با تحلیل مشکل های شما نرم افزار های مفیدی برای تسریع کار شما خلق می کنیم.

                        </div>



                    </div>

                    {/* item  */}

                                        {/* item  */}
                                        <div className="flex flex-col justify-center max-md:items-center items-start p-7  bg-[#F9F9FB] border border-white rounded-3xl shadow-2xl p-7  bg-[#F9F9FB] border border-white rounded-3xl shadow-2xl">
                        <div className="flex md:flex-row flex-col items-center justify-start  gap-4">
                            <div className="p-3 bg-primary text-white rounded-2xl">
                            <svg width="50px" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
 <path d="M13 5C13 6.10457 10.5376 7 7.5 7C4.46243 7 2 6.10457 2 5M13 5C13 3.89543 10.5376 3 7.5 3C4.46243 3 2 3.89543 2 5M13 5V6.5M2 5V17C2 18.1046 4.46243 19 7.5 19M7.5 11C7.33145 11 7.16468 10.9972 7 10.9918C4.19675 10.9 2 10.0433 2 9M7.5 15C4.46243 15 2 14.1046 2 13M22 11.5C22 12.6046 19.5376 13.5 16.5 13.5C13.4624 13.5 11 12.6046 11 11.5M22 11.5C22 10.3954 19.5376 9.5 16.5 9.5C13.4624 9.5 11 10.3954 11 11.5M22 11.5V19C22 20.1046 19.5376 21 16.5 21C13.4624 21 11 20.1046 11 19V11.5M22 15.25C22 16.3546 19.5376 17.25 16.5 17.25C13.4624 17.25 11 16.3546 11 15.25" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
 </svg>
                            </div>
                            <div className="flex flex-col  justify-center gap-3 md:items-start pt-[0.525rem]">

                                <div className="font-bold text-primary text-lg text-center">مدیریت مالی</div>
                                <div>Financial</div>

                            </div>
                        </div>


                        <div className="text-gray-500 text-right pt-8 leading-loose	max-md:hidden">
                        نرم افزار های مدیریت مالی به شما در مدیریت و بررسی روند سرمایه خود کمک می کند.

                        </div>



                    </div>

                    {/* item  */}

                    

                                  


                </div>
                   
            </div>
        </section>
    )
}

export default Servis;