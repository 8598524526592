const WhyUs = () => {
    return (
        <section className="mt-14">  
            <div className="flex md:flex-row flex-col items-center justify-center pt-36 gap-20 container">




            <section className="  mx-4">
                <div className="flex flex-col items-center">
                <div className="  bg-primary  rounded-[40px] flex flex-col container p-10">
                    <div className="flex justify-center">
                    <div className="rounded-[40px] shadow-xl w-[290px] h-[220px] md:w-[390px] md:h-[220px] relative bg-white imgCover ">

                        <div className="absolute  opacity-10 bg-slate-900 w-full h-full rounded-[40px] "></div>
                        <div className="absolute flex justify-center items-center w-full h-full">
                            <div className="bg-white p-12 rounded-[40px] shadow-2xl opacity-95">
                                <svg className="text-gray-700" width="50px" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5 4.98951C5 4.01835 5 3.53277 5.20249 3.2651C5.37889 3.03191 5.64852 2.88761 5.9404 2.87018C6.27544 2.85017 6.67946 3.11953 7.48752 3.65823L18.0031 10.6686C18.6708 11.1137 19.0046 11.3363 19.1209 11.6168C19.2227 11.8621 19.2227 12.1377 19.1209 12.383C19.0046 12.6635 18.6708 12.886 18.0031 13.3312L7.48752 20.3415C6.67946 20.8802 6.27544 21.1496 5.9404 21.1296C5.64852 21.1122 5.37889 20.9679 5.20249 20.7347C5 20.467 5 19.9814 5 19.0103V4.98951Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                    </div>



                </div>

                  

                </div>

               


            </section>
                    


                <div className="text-right">
                      <h2 className={` font-bold text-4xl  mb-3 max-w-[750px]  leading-normal text-gray-800`}>چرا<span className="text-primary">آونگ فناوران </span> را انتخاب کنم ؟               </h2>
                      <p className="text-lg font-medium text-gray-500 max-w-[700px] leading-relaxed">
                      شرکت آونگ فناوران پارس پویا یک شرکت دانش بنیان با کارشناسان و متخصصین  در حوزه فناوری اطلاعات با هدف تولید نرم‌افزارهای تحت وب، اپلیکیشن و نرم‌افزارهای اداری، مشغول فعالیت می باشد. این شرکت با تمرکز بر طراحی و توسعه محصولات باکیفیت، تلاش می‌کند تا نیازهای نرم‌افزاری کسب‌وکارهای مختلف را با راهکارهای خلاقانه و نوآورانه پاسخ دهد.


                      </p>

                </div>







            
                
                   
            </div>
        </section>
    )
}

export default WhyUs;