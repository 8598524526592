const Pr = () => {
    return (
        <section class="py-20 relative mt-20">
        <div class="w-full max-w-2xl lg:max-w-7xl px-6 lg:px-8 mx-auto">
            {/* <h1 class="font-manrope font-medium text-4xl text-gray-900 mb-10 max-md:text-center">Professional Project
                Achievements</h1> */}
                <h2 className={` font-bold text-4xl    mb-16  text-gray-800 text-center`}> محصولات <span className="text-primary font-bold"> آونگی </span>                  </h2>

            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 text-right">

                
                <div class="flex items-center flex-col gap-8 w-full group">
                    <div class="block">
                        <img class="rounded-3xl object-cover" src="/img/to.png" alt="Project Achievements of Sketch"></img>
                    </div>
                    <div class="flex items-center justify-between max-w-[406px] lg:max-w-full w-full lg:px-0">
                        <div class="block">
                            <h4 class="text-2xl font-manrope font-semibold text-gray-900 mb-1">هوشی طور</h4>
                            <p class="font-medium text-lg text-gray-400"> AI platform</p>
                        </div>
                        
                    </div>
                </div>



                <div class="group flex items-center flex-col gap-8 w-full">
                    <div class="block">
                        <img class="rounded-3xl object-cover" src="/img/nu.png" alt="Project Achievements of Figma"></img>
                    </div>
                    <div class="flex items-center justify-between max-w-[406px] lg:max-w-full w-full lg:px-0">
                        <div class="block">
                            <h4 class="text-2xl font-manrope font-semibold text-gray-900 mb-1">نامیت</h4>
                            <p class="font-medium text-lg text-gray-400">Web platform
                            </p>
                        </div>
                        
                    </div>
                </div>
                <div
                    class="group flex items-center flex-col gap-8 w-full md:w-1/2 mx-auto md:col-span-2 lg:col-span-1 lg:w-full">
                    <div class="block">
                        <img class="rounded-3xl object-cover" src="/img/bo.png" alt="Project Achievements of Frame.io"></img>
                    </div>
                    <div class="flex items-center justify-between max-w-[406px] lg:max-w-full w-full lg:px-0">
                        <div class="block">
                            <h4 class="text-2xl font-manrope font-semibold text-gray-900 mb-1">نرم افزار بودجه نگار</h4>
                            <p class="font-medium text-lg text-gray-400">Web Application
                            </p>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>

    </section>
    )
}

export default Pr;