import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";


import Header from './comp/Header.js';

import Footer from './comp/Footer.js';
import Index from './pages/Index.js';
import ContactUs from './pages/ContactUs.js';

function App() {
  return (
    <div className="App">



      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Index />} />
          <Route path="/contactus" element={<ContactUs />} />

        </Routes>
      <Footer />

      </Router>

   
    </div>
  );
}

export default App;
